<template>

  <container :loading="loading" :body-props="{class: 'relative'}">

    <template v-slot:heading>
      <div class="sticky top-0">
        {{ !program ? 'Loading...':`${program.courseName} ${program.batchName}` }}
      </div>
    </template>

    <div>

      <div>
        <div class="text-center text-2xl mt-10">{{ content.subjectName }}</div>
        <div class="text-center mt-2 "><position :value="content.serial" /> {{ content.type}}</div>
      </div>

      <div class="mt-3">
        <btn :router-path="`/my/courses/${programId}/schedules`"   variant="success-outline">Back To Schedule</btn>
      </div>

      <div class="border-t border-gray-300 py-2 mt-4 relative" v-fade-in="!loading">

        <div class="grid md:grid-cols-3 gap-4 py-4 w-full">
          <exam-item v-for="exam in exams" :exam="exam" :key="exam.id" :program-details="false"></exam-item>
        </div>

        <div v-if="materials.length > 0" class="flex-col md:flex-row flex w-full">

          <div class="order-2 md:order-1" :class="{ 'flex flex-col w-full md:w-64 md:border-r pr-4  sticky top-0':!!materialId, 'grid gap-4 md:grid-cols-3 w-full py-4': !materialId }" >
            <material-item v-for="material in materials" :material="material" :router-prefix="routerPrefix" :is-list="!!materialId" ></material-item>
          </div>

          <div class="flex-1 md:ml-3 order-1 md:order-2 ">
            <router-view :material="getMaterial"></router-view>
          </div>

        </div>

      </div>

    </div>

  </container>
</template>

<script>
import Container from "../../layouts/container";
import Position from "../exams/components/position";
import ExamItem from "../exams/components/exam-item";
import SvgPlay from "../../../components/svgs/svg-play";
import MaterialItem from "./components/material-item";
import Btn from "../../../components/btn";
export default {
  name: "view",
  components: {Btn, MaterialItem, SvgPlay, ExamItem, Position, Container},
  data() {
    return {
      loading: true,
      program: {},
      content: {}
    }
  },
  computed: {
    getMaterial(){
      const items = this.materials.filter( m => String( m.materialId ) === String( this.materialId ) );
      return items[0] || {};
    },

    materialId( ){
      return this.$route.params.material_id;
    },
    contentId( ){
      return this.$route.params.content_id;
    },
    subjectSlug(){
      return this.$route.params.subject;
    },
    typeSlug(){
      return this.$route.params.type;
    },
    serial(){
      return this.$route.params.serial;
    },
    programId( ){
      return this.$route.params.program_id;
    },
    exams(){
      return Array.isArray( this.content.exams ) ? this.content.exams : [ ];
    },
    materials(){
      return Array.isArray( this.content.materials ) ? this.content.materials : [ ];
    }
    ,
    routerPrefix(){
      return `/my/course/${this.programId}/content/${this.contentId}/${this.subjectSlug}/${this.typeSlug}/${this.serial}`;
    }

  },
  methods: {
    loadExams() {
      const params = {};

      if ( this.programId &&  this.contentId ) {

        this.$store.dispatch('batch/viewContent', {
          programId: this.programId,
          contentId: this.contentId,
          success: ({data}) => {

            this.program = data.program;
            this.content = data.content;

          },
          error: (response) => {

          }
        }).finally(() => this.loading = false)

      }
    }

  },
  created() {
    this.loadExams();
  }
}

</script>

<style scoped>

</style>