<template>

  <router-link class="cursor-pointer border rounded shadow px-4 text-red-600 hover:text-red-400 "
               :class="{'flex items-center h-16 mb-5': isList, 'bg-green-300':isLinkActive}"
               :title="material.title"
               :to="`${routerPrefix}/${material.materialId}/material`">

    <div :class="{'order-2 ml-2': isList}">
      <component :is="thumbComponent" class="cursor-pointer " :class="{'h-10': isList}"></component>
    </div>

    <div class="text-gray-700 hover:text-gray-900" :class="{'order-1 flex-grow py-3  ': isList}">
      <div class="w-40 overflow-hidden h-12  pt-1">{{ material.title }}</div>
    </div>

  </router-link>

</template>

<script>
import SvgPlay from "../../../../components/svgs/svg-play";
import SvgArticle from "../../../../components/svgs/svg-article";
export default {

  name: "material-item",
  components: {SvgArticle, SvgPlay},
  props: {
    material: { type: Object, default: {}},
    routerPrefix: { type: String, default: '' },
    isList: { type: Boolean, default: false },
  },

  computed: {
    isLinkActive(){
      return this.$route.params.material_id == this.material.materialId;
    },
    thumbComponent(){
      switch ( Number(this.material.typeId) ){
        case 3:
          return 'svg-article';
        default:
          return 'svg-play'
      }
    }

  }

}
</script>

<style scoped>

</style>